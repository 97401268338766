export const useFoodStore = defineStore('food', () => {
  const API_ENDPOINT = '/food';
  const $api = useApi();

  const activeFoodId = ref();
  const activeFoodPortions = ref([]);
  const activeFoodNutrients = ref([]);

  async function setActiveFoodId(foodId: string) {
    activeFoodId.value = foodId;
  }

  async function fetchActiveFoodPortions() {
    try {
      const { data } = await $api.get(
        `${API_ENDPOINT}/${activeFoodId.value}/portions`,
      );
      activeFoodPortions.value = data;
    } catch (e) {}
  }

  async function fetchActiveFoodNutrients() {
    try {
      const { data } = await $api.get(
        `${API_ENDPOINT}/${activeFoodId.value}/nutrients`,
      );
      const customOrder = [
        'macro',
        'vitamin',
        'mineral',
        'fatty-acid',
        'amino-acid',
        'cholesterol',
        'micro',
      ];
      activeFoodNutrients.value = data.sort(
        (a, b) => customOrder.indexOf(a.type) - customOrder.indexOf(b.type),
      );
    } catch (e) {}
  }

  return {
    activeFoodPortions,
    activeFoodNutrients,
    setActiveFoodId,
    fetchActiveFoodPortions,
    fetchActiveFoodNutrients,
  };
});
